<template>
  <v-container
    :fluid="$vuetify.breakpoint.md"
    v-if="!loading && menus && !localLoading"
    class="pa-8"
  >
    <v-alert text color="info" v-if="!hasMenus">
      <h3 class="text-h5">
        Adding Menus
      </h3>
      <div>
        <p>
          There are two types of menus - <b>parent menus</b> and
          <b>regular menus.</b>
        </p>
        <p>
          Parent Menus are high-level categories like Lunch, Breakfast, Dinner,
          etc. Create parent menus for your major mealtimes or dining
          categories.
        </p>
        <p>
          Regular Menus are more specific types of offerings like Drinks,
          Appetizers, Salads, Entrees, Sides, Desserts, etc. Regular menus
          should be added under a parent menu.
        </p>
      </div>

      <v-divider class="my-4 info" style="opacity: 0.22"></v-divider>

      <v-row align="center" no-gutters>
        <v-col class="grow">
          <b>
            For example:
          </b>
          <ul>
            <li>Parent menu: Lunch</li>
            <li>Regular menus: Soups, Salads, Sandwiches, Entrees</li>
            <li>Parent menu: Dinner</li>
            <li>Regular menus: Appetizers, Steaks, Seafood, Pasta, Sides</li>
          </ul>

          <p class="mt-3">
            Be sure to structure your regular menus under the appropriate parent
            menus. This enables customers to easily browse your offerings by
            meal or dining category.
          </p>
        </v-col>
      </v-row>
    </v-alert>
    <!-- PARENT MENUS -->
    <v-row>
      <v-col cols="12">
        <h2>
          Parent Menus
          <v-btn
            color="light-blue"
            small
            dark
            fab
            @click.native.stop="showNameDialog('parent')"
          >
            <v-icon>add</v-icon>
          </v-btn>
        </h2>
      </v-col>
      <v-col cols="12" class="body-2"
        >For example,
        <i>Lunch Special</i>
        or <i>Breakfast</i> menu. Parent menu <b>ONLY</b> holds regular menus
        like drinks, appetizers, main menu.
      </v-col>
    </v-row>
    <v-row v-if="!loading && parentMenus">
      <v-col cols="12" class="d-flex my-0 py-0">
        <draggable
          :list="parentMenus"
          class="d-flex flex-row overflow-auto"
          @change="changeParentMenuOrder"
          handle=".menu-handle"
        >
          <div v-for="(pmenu, key) in parentMenus" :key="key">
            <v-card class="ma-4" height="360" width="240">
              <v-card-title
                :class="(pmenu.isActive ? 'blue' : 'red') + ' white--text pa-1'"
              >
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex  justify-space-between">
                    <div class="menu-handle py-0 px-2 ">
                      <v-icon class="white--text">mdi-cursor-move</v-icon>
                    </div>
                    <div class="d-flex w-100 justify-space-between card-title">
                      <v-edit-dialog
                        @open="tmp = pmenu.name"
                        @save="saveName(pmenu.id, tmp)"
                        large
                      >
                        <div class="fs-16 white--text nowrap">
                          {{ pmenu.name }}
                        </div>
                        <div slot="input" class="mt-3">Update menu name</div>
                        <v-text-field
                          slot="input"
                          label="Edit"
                          v-model="tmp"
                          single-line
                          autofocus
                        ></v-text-field>
                      </v-edit-dialog>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn class="ma-0" icon v-on="on">
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="dropdown-list">
                          <v-list-item
                            :to="
                              '/product/menu/' + uid + '/editmenu/' + pmenu.id
                            "
                          >
                            <v-icon class="mr-3">edit</v-icon>
                            <span>Edit</span>
                          </v-list-item>
                          <v-list-item
                            @click="deleteMenu(pmenu.id, pmenu.isActive)"
                          >
                            <v-icon color="red" class="mr-3">delete</v-icon>
                            <span>Delete</span>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-row no-gutters class="sub-title" justify="space-between">
                <v-col class="caption" cols="6"
                  >Menus:
                  {{ pmenu.children ? pmenu.children.length : 0 }}</v-col
                >
                <v-col class="caption" cols="2"
                  ><v-tooltip
                    v-if="!pmenu.useBusinessHours"
                    left
                    color="blue lighten-5"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon color="pink" v-on="on"
                        >mdi-calendar-clock</v-icon
                      ></template
                    >
                    <span class="body-2">Uses Custom Hours</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-card-text class="pa-1">
                <vue-perfect-scrollbar
                  style="height:260px"
                  :settings="settings"
                >
                  <v-list v-if="pmenu.children && pmenu.children.length > 0">
                    <draggable
                      :list="pmenu.children"
                      group="childrenMenus"
                      @change="evt => addNewSubMenu(evt, pmenu)"
                      style="height:260px"
                    >
                      <v-list-item
                        v-for="(menu, key) in menuInParent(pmenu)"
                        :key="key"
                      >
                        <template v-if="menu">
                          <v-list-item-content>{{
                            menu.name | truncate(40)
                          }}</v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              small
                              class="ma-0"
                              icon
                              @click="removeMenu(menu.id, pmenu.id)"
                            >
                              <v-icon :small="true" color="secondary"
                                >mdi-delete</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </draggable>
                  </v-list>
                  <draggable
                    v-else
                    :list="['']"
                    group="childrenMenus"
                    style="height:260px"
                    @change="evt => addNewSubMenuInEmptyMenu(evt, pmenu)"
                    >Drag and drop regular menus here.</draggable
                  >
                </vue-perfect-scrollbar>
              </v-card-text>
            </v-card>
          </div>
        </draggable>
      </v-col>
    </v-row>
    <!-- SUB MENUS -->
    <v-row>
      <v-col cols="12" class="d-flex align-center">
        <h3 class="ma-4">Regular Menus</h3>
        <v-btn
          color="light-blue"
          dark
          small
          fab
          @click.native.stop="showNameDialog('')"
        >
          <v-icon>add</v-icon>
        </v-btn>
        <v-col cols="12" class="body-2"
          >For example,
          <i>Drinks, Appetizers, Salads, Entrees, Sides, Desserts</i>
        </v-col>
      </v-col>
    </v-row>
    <v-row v-if="!loading && subMenus" no-gutters>
      <v-col cols="12" class="d-flex my-0 py-0">
        <draggable
          :list="subMenuIds"
          :group="{ name: 'childrenMenus', pull: 'clone', put: false }"
          class="d-flex flex-row overflow-auto"
          handle=".menu-handle"
          @change="changeSubMenuOrder"
        >
          <div v-for="(smID, key) in subMenuIds" :key="key">
            <v-card class="ma-4" height="340" width="240">
              <v-card-title
                :class="
                  (getSubMenu(smID).isActive ? 'blue' : 'red') +
                    ' white--text pa-1 ma-0'
                "
              >
                <v-row>
                  <v-col cols="12" class="d-flex py-2 px-3 ">
                    <div class="menu-handle pr-2">
                      <v-icon class="white--text">mdi-cursor-move</v-icon>
                    </div>
                    <div class="d-flex w-100 justify-space-between card-title">
                      <v-edit-dialog
                        @open="tmp = getSubMenu(smID).name"
                        @save="saveName(getSubMenu(smID).id, tmp)"
                        large
                      >
                        <div class="body-1 white--text nowrap">
                          {{ getSubMenu(smID).name | truncate(20) }}
                        </div>
                        <div slot="input" class="mt-3">Update menu name</div>
                        <v-text-field
                          slot="input"
                          label="Edit"
                          v-model="tmp"
                          single-line
                          autofocus
                        ></v-text-field>
                      </v-edit-dialog>
                      <v-menu offset-y>
                        <template v-slot:activator="{ on }">
                          <v-btn class="ma-0" icon v-on="on">
                            <v-icon>more_vert</v-icon>
                          </v-btn>
                        </template>
                        <v-list class="dropdown-list">
                          <v-list-item
                            :to="'/product/menu/' + uid + '/editmenu/' + smID"
                          >
                            <v-icon class="mr-3">edit</v-icon>
                            <span>Edit</span>
                          </v-list-item>
                          <v-list-item
                            @click="deleteMenu(smID, getSubMenu(smID).isActive)"
                          >
                            <v-icon color="red" class="mr-3">delete</v-icon>
                            <span>Delete</span>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </v-col>
                </v-row>
              </v-card-title>
              <v-row no-gutters class="sub-title" justify="space-between">
                <v-col cols="6" class="caption"
                  >Items:
                  {{
                    getSubMenu(smID).items ? getSubMenu(smID).items.length : 0
                  }}</v-col
                >
                <v-col class="caption" cols="2">
                  <v-tooltip
                    v-if="getSubMenu(smID).useParentHours"
                    left
                    color="blue lighten-5"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="pink"
                        >mdi-format-indent-increase</v-icon
                      >
                    </template>
                    <span class="body-2">Uses Parent Menu Hours</span>
                  </v-tooltip>
                  <v-tooltip
                    v-else-if="!getSubMenu(smID).useBusinessHours"
                    left
                    color="blue lighten-5"
                  >
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="pink">mdi-calendar-clock</v-icon>
                    </template>
                    <span class="body-2">Uses Custom Hours</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-card-text class="pa-1">
                <vue-perfect-scrollbar
                  style="height:260px"
                  :settings="settings"
                >
                  <v-list v-if="getSubMenu(smID).items.length > 0">
                    <draggable
                      :list="getSubMenu(smID).items"
                      group="products"
                      @change="evt => addNewProduct(evt, getSubMenu(smID))"
                      style="height:260px"
                    >
                      <v-list-item
                        v-for="(product, key) in productInMenu(
                          getSubMenu(smID)
                        )"
                        :key="key"
                        class="pl-0 pr-1 d-flex"
                      >
                        <template v-if="product">
                          <v-list-item-avatar
                            v-if="getProductImage(product.id)"
                            class="pa-1 ma-0"
                          >
                            <img
                              :src="getProductImage(product.id)"
                              class="image-round"
                              elevation="2"
                            />
                          </v-list-item-avatar>
                          <v-list-item-icon v-else class="ml-2 mr-2">
                            <v-icon color="grey">mdi-image-off</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            product.name | truncate(40)
                          }}</v-list-item-content>
                          <v-list-item-action>
                            <v-btn
                              icon
                              color="info"
                              :to="`/product/edit/${uid}/${product.id}`"
                            >
                              <v-icon small>edit</v-icon>
                            </v-btn>
                          </v-list-item-action>
                          <v-list-item-action class="d-flex flex-row">
                            <v-btn
                              small
                              class="ma-0"
                              icon
                              @click="removeProduct(product.id, smID)"
                            >
                              <v-icon :small="true" color="secondary"
                                >mdi-delete</v-icon
                              >
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </draggable>
                  </v-list>
                  <draggable
                    v-else
                    :list="['']"
                    group="products"
                    @change="
                      evt => addNewProductInEmptyMenu(evt, getSubMenu(smID))
                    "
                    style="height:260px"
                    >Drag and drop menus here.</draggable
                  >
                </vue-perfect-scrollbar>
              </v-card-text>
            </v-card>
          </div>
        </draggable>
      </v-col>
    </v-row>
    <!-- Products -->
    <v-row align="center" class="my-4">
      <v-col cols="12" class="d-flex align-center">
        <h4 class="ma-2">Products</h4>

        <search-product :dishList="dishList" @filterProduct="filterProduct" />
      </v-col>
      <i class="small mx-4">Drag and Drop products to a regular menu</i>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex my-0 py-0">
        <template v-if="productIds && productIds.length">
          <draggable
            :list="productIds"
            :group="{ name: 'products', pull: 'clone', put: false }"
            class="d-flex flew-row overflow-auto"
            handle=".product-handle"
          >
            <div v-for="(pID, key) in productIds" :key="key">
              <v-card class="ma-4" width="200">
                <v-img
                  v-if="getProductImage(pID)"
                  class="image-rect"
                  :src="getProductImage(pID)"
                >
                  <div class="product-handle py-2 px-3">
                    <v-icon class="white--text">mdi-cursor-move</v-icon>
                  </div>
                </v-img>
                <div v-else class="image-rect">
                  <div class="product-handle py-2 px-3">
                    <v-icon class="white--text">mdi-cursor-move</v-icon>
                  </div>
                  <div class="d-flex justify-center mt-2">
                    <v-icon color="grey">mdi-image-off</v-icon>
                  </div>
                  <span class="d-flex justify-center grey--text">No Image</span>
                </div>
                <v-card-title class="justify-center pa-2">
                  <h4 class="ma-0 nowrap-product">{{ getProductName(pID) }}</h4>
                </v-card-title>
              </v-card>
            </div>
          </draggable>
        </template>
        <template v-else>
          <span class="pa-4">No available products</span>
        </template>
      </v-col>
    </v-row>
    <delete-confirmation-dialog
      ref="deleteConfirmationDialog"
      heading="Deleting Menu"
      message="Are you sure you want to delete this menu?"
      @onConfirm="onDeleteMenu"
    ></delete-confirmation-dialog>

    <v-dialog v-model="activeMenuDialog" width="700px">
      <v-card>
        <v-card-title>
          <v-icon class="mb-2 mr-3" color="grey">block</v-icon>
          <h2>Can Not Delete Active Menu</h2>
        </v-card-title>
        <h4 class="px-4 mb-4">Please set menu to inactive before deleting.</h4>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="activeMenuDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="nameDialog" persistent max-width="320">
      <v-card>
        <v-card-title class="headline">
          {{
            menuType === 'parent'
              ? $t('Common.parent_menu_name')
              : $t('Common.sub_menu_name')
          }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            :label="$t('Common.edit')"
            v-model="createMenuName"
            single-line
            autofocus
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="takein white--text" @click="addMenuPlaceholder"
            >OK</v-btn
          >
          <v-btn color="default black--text" @click="nameDialog = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import SearchProduct from './SearchProduct'

export default {
  data: () => {
    return {
      parentMenus: [],
      subMenus: [],
      subMenuIds: [],
      products: [],
      // productIds: [],
      tmp: null,
      menus: [],
      activeMenuDialog: false,
      selectedMenuId: null,
      filteredList: [],
      settings: {
        maxScrollbarLength: 160
      },
      nameDialog: false,
      menuType: '',
      createMenuName: '',
      localLoading: true
    }
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      dishList: 'dishList',
      isAdmin: 'isAdmin'
    }),
    productIds() {
      return this.filteredList.map(d => d.id)
    },
    hasMenus() {
      return this.menus && this.menus.length > 0
    }
  },
  components: {
    draggable,
    SearchProduct
  },
  async mounted() {
    this.$store.dispatch('checkAdmin').then(() => {
      this.uid =
        this.isAdmin && this.$route.params.uid
          ? this.$route.params.uid
          : this.profile
          ? this.profile.id
          : null

      this.$store.dispatch('setLoading', true)
      this.$store.dispatch('loadDishList', { uid: this.uid }).then(list => {
        this.products = list
        this.filteredList = list
        console.log(this.products, 'products')
      })
      this.loadMenus()
    })
  },
  methods: {
    filterProduct(productList) {
      this.filteredList = productList
    },
    showNameDialog(menuType) {
      this.createMenuName = ''
      this.nameDialog = true
      this.menuType = menuType
    },
    changeParentMenuOrder: function(evt) {
      if (evt.moved) {
        let newParentMenus = []
        for (let k = 0; k < this.parentMenus.length; k++) {
          for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].id === this.parentMenus[k].id) {
              newParentMenus.push({ ...this.menus[i], position: k })
              break
            }
          }
        }
        this.menus = this.menus.filter(m => m.type !== 'parent')
        this.menus = [...newParentMenus, ...this.menus]
        this.$store.dispatch('saveMenus', { menu: this.menus, uid: this.uid })
      }
    },
    changeSubMenuOrder: function(evt) {
      if (evt.moved) {
        let newSubMenus = []
        let endPosition = 0
        const allMenus = this.menus
        if (allMenus && allMenus.length > 0) {
          endPosition = Math.max(...allMenus.map(m => m.position))
          if (!endPosition) {
            endPosition = allMenus.length + 1
          }
        }

        for (let k = 0; k < this.subMenuIds.length; k++) {
          for (let i = 0; i < this.menus.length; i++) {
            if (this.menus[i].id === this.subMenuIds[k]) {
              newSubMenus.push({
                ...this.menus[i],
                position: endPosition + k + 1
              })
              break
            }
          }
        }
        this.menus = this.menus.filter(m => m.type === 'parent')
        this.menus = [...this.menus, ...newSubMenus]
        this.$store.dispatch('saveMenus', { menu: this.menus, uid: this.uid })
      }
    },
    addNewSubMenu: function(evt, menu) {
      console.log(evt, menu, 'PPP')
      menu.children = [...new Set(menu.children)]
      this.$store.dispatch('saveMenus', { menu: menu, uid: this.uid })
    },
    addNewSubMenuInEmptyMenu: function(evt, menu) {
      menu.children = [evt.added.element]
      this.$store.dispatch('saveMenus', { menu: menu, uid: this.uid })
    },
    addNewProduct: function(evt, smenu) {
      smenu.items = [...new Set(smenu.items)]
      this.$store.dispatch('saveMenus', { menu: smenu, uid: this.uid })
    },
    addNewProductInEmptyMenu: function(evt, smenu) {
      smenu.items = [evt.added.element]
      this.$store.dispatch('saveMenus', { menu: smenu, uid: this.uid })
    },
    loadMenus() {
      if (this.uid) {
        this.$store.dispatch('loadMenus', { uid: this.uid }).then(list => {
          console.log('Total menus loaded: ', list.length)
          list.sort((a, b) =>
            a.position > b.position ? 1 : b.position > a.position ? -1 : 0
          )

          this.menus = list
          this.subMenus = this.menus.filter(menu => menu.type !== 'parent')
          this.subMenuIds = this.subMenus.map(s => s.id)

          //remove empty items of sub menu
          this.subMenus.forEach(submenu => {
            if (!submenu.items) submenu.items = []
            else submenu.items = submenu.items.filter(item => !!item)
          })
          //remove products if not exist
          for (let i = 0; i < this.subMenus.length; i++) {
            let new_items = []

            for (let j = 0; j < this.subMenus[i].items.length; j++) {
              if (
                this.products.map(d => d.id).includes(this.subMenus[i].items[j])
              ) {
                new_items.push(this.subMenus[i].items[j])
              }
            }
            this.subMenus[i].items = new_items
          }

          this.parentMenus = this.menus.filter(menu => menu.type === 'parent')
          console.log(JSON.stringify(this.parentMenus), 'parent!!!')
          //remove children menus if not exist
          for (let i = 0; i < this.parentMenus.length; i++) {
            let new_children = []
            for (let j = 0; j < this.parentMenus[i].children.length; j++) {
              if (this.subMenuIds.includes(this.parentMenus[i].children[j])) {
                new_children.push(this.parentMenus[i].children[j])
              }
            }
            this.parentMenus[i].children = new_children
          }
          this.$store.dispatch('setLoading', false)
          this.localLoading = false
        })
      }
    },
    deleteMenu(id, isActive) {
      this.selectedMenuId = id
      if (isActive) {
        // do not allow user to delete menu that is active
        this.activeMenuDialog = true
      } else {
        this.$refs.deleteConfirmationDialog.openDialog()
      }
    },
    onDeleteMenu() {
      console.log('deleting', this.selectedMenuId)
      //also be deleting ParentMenu's SubMenu
      const selectedMenu = this.menus.filter(
        m => m.id == this.selectedMenuId
      )[0]
      if (selectedMenu.type !== 'parent') {
        this.menus.forEach(pmenu => {
          if (pmenu.type === 'parent') {
            pmenu.children = pmenu.children.filter(
              pc => pc !== this.selectedMenuId
            )
          }
        })
      }
      console.log(
        this.menus.filter(m => m.type === 'parent'),
        'after deleted!'
      )
      this.$store
        .dispatch('saveMenus', { menu: this.menus, uid: this.uid })
        .then(() => {
          this.$store.dispatch('deleteMenu', this.selectedMenuId).then(() => {
            this.loadMenus()
            this.$refs.deleteConfirmationDialog.close()
          })
        })
    },
    saveName(item, newName) {
      let menu = this.menus.filter(m => m.id == item)
      menu = menu.length > 0 ? menu[0] : []
      menu.name = newName

      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('saveMenus', { menu: menu, uid: this.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    addMenuPlaceholder() {
      this.nameDialog = false
      let lastParentMenuIndex = 0,
        lastSubMenuIndex = 0
      let parentMenus = this.menus.filter(m => m.type === 'parent'),
        allMenus = this.menus

      let newMenu = {
        isActive: false
      }
      if (this.menuType) {
        if (parentMenus && parentMenus.length > 0) {
          lastParentMenuIndex =
            Math.max(...parentMenus.map(m => m.position)) + 1
          // if (!lastParentMenuIndex) {
          //   endPosition = parentMenus.length + 1
          // }
        }
        newMenu.type = 'parent'
        newMenu.name = this.createMenuName
        newMenu.position = lastParentMenuIndex
        newMenu.children = []
      } else {
        if (allMenus && allMenus.length > 0) {
          lastSubMenuIndex = Math.max(...allMenus.map(m => m.position)) + 1
          if (!lastSubMenuIndex) {
            lastSubMenuIndex = allMenus.length + 1
          }
        }
        newMenu.name = this.createMenuName
        newMenu.position = lastSubMenuIndex
        newMenu.items = []
      }
      this.menus.push(newMenu)

      this.$store.dispatch('setLoading', true)
      this.$store
        .dispatch('saveMenus', { menu: newMenu, uid: this.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
          this.loadMenus()
        })
    },
    getSubMenu(subMenuID) {
      const sMenu = this.subMenus.filter(s => s.id === subMenuID)[0]
      return sMenu ? sMenu : null
    },
    getProductImage(productID) {
      const product = this.products.filter(p => p.id === productID)[0]
      return product && product.photos && product.photos.length > 0
        ? product.photos[0].url || product.photos[0].imageUrl
        : null
    },
    getDragabble(productID) {
      const product = this.products.filter(p => p.id === productID)[0]
      return product && product.visibility && product.visibility === 'public'
        ? true
        : false
    },
    getProductName(productID) {
      const product = this.products.filter(p => p.id === productID)[0]
      return product ? product.name : ''
    },
    removeMenu: function(subId, parentId) {
      console.log(`Removed ${subId} from  ${parentId}`)

      let parentMenu = this.menus.filter(m => m.id == parentId)
      parentMenu = parentMenu.length > 0 ? parentMenu[0] : null
      if (!parentMenu) {
        return
      }

      const list = parentMenu.children.filter(item => item !== subId)
      parentMenu.children = list

      this.changeVisibility(parentMenu)
      this.$store
        .dispatch('saveMenus', { menu: parentMenu, uid: this.uid })
        .then(() => {
          this.$store.dispatch('setLoading', false)
        })
    },
    removeProduct: function(id, mid) {
      console.log(`Remove ${id} from  ${mid}`)
      let menu = this.menus.filter(m => m.id == mid)

      menu = menu.length > 0 ? menu[0] : null
      if (!menu) {
        return
      }

      const list = menu.items.filter(item => item !== id)
      menu.items = list

      this.changeVisibility(menu)
      this.$store.dispatch('saveMenus', { menu: menu, uid: this.uid })
    },
    menuInParent(menu) {
      if (!menu) {
        return
      }
      let list = []
      menu.children.forEach(submenu => {
        if (this.menus) {
          let menu = this.menus.filter(s => s.id === submenu)
          list.push(menu[0])
        }
      })
      return list
    },
    productInMenu(sMenu) {
      let list = []
      if (!sMenu) {
        return
      }
      if (this.products) {
        sMenu.items.forEach(productId => {
          const product = this.products.filter(p => p.id === productId)
          if (product[0]) list.push(product[0])
        })
      }
      return list
    },
    // changes visibility depending on the number of products in a menu
    // needed for when user removes item from menu, leaving it without any items
    changeVisibility(menu) {
      let isVisible = false
      if (menu.type && menu.type === 'parent') {
        isVisible = menu.children && menu.children.length > 0
      } else {
        isVisible = menu.items && menu.items.length > 0
      }

      // sets menu to inactive if there are no items
      if (!isVisible) {
        menu.isActive = isVisible
        menu.isPublic = isVisible
        menu.followerOnly = isVisible
      }
    }
  }
}
</script>
<style scoped>
.image-round {
  border-radius: 50%;
  overflow: hidden;
  width: 30px;
  height: 30px;
}
.image-rect {
  border: gainsboro 3px solid;
  overflow: hidden;
  width: 100%;
  height: 135px;
}
.sub-title {
  /* background-color: rgba(0, 0, 0, 0.15); */
  padding-left: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
  display: flex;
  justify-content: space-between;
}

.handle {
  padding: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.product-handle {
  padding: 4px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}
.card-title {
  max-height: 36px;
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 4px;
  padding-bottom: 0;
  max-width: 170px;
}
.nowrap-product {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 8px;
  padding-bottom: 8px;
  max-width: 190px;
}
</style>
